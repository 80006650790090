.splash {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  opacity: 1;
  visibility: visible;
  transition: visibility 0s linear 500ms, opacity 500ms;
}

.animate-out-splash {
  opacity: 0;
  visibility: hidden;
  transition: visibility 0s linear 500ms, opacity 500ms;
}