.list-wrapper {
  padding: 200px 80px;

  h2 {
    color: $evrsrc-dark-green;
    font-family: SlatePro-Bold;
  }

  p {
    margin-bottom: 40px;
  }
  
  .energy-list-container {
    display: flex;
    flex-direction: column;

    @media (max-width: 1700px) {
      height: 500px;
      overflow: auto;
    }

    .energy-icon {
      position: relative;
      left: 0;
      top: 0;
    }

    .icon-wrapper {
      margin-bottom: 20px;
    }

    img {
      margin-right: 25px;
    }

    h5 {
      font-family: SlatePro-Bold;
      font-size: 1.56em;
      margin-bottom: 0;
    }
  }
}