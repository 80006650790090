.right-column-container {
  height: 100%;
  width: calc(100vw - 500px);
  position: relative;

  p {
    font-size: 1.3em;
    margin-bottom: 20px;
  }
}
