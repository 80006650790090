.bubble {
  position: relative;
  background: white;
  color: $evrsrc-dark-green;
  text-align: center;
  font-family: SlatePro-Bold;
  font-size: 1.56em;
  margin-top: 80px;
  margin-bottom: 50px;
  border-radius: 6px;
  padding-top: 10px;
  line-height: 33px;
  width: 90%;

  @media(max-width: 1700px) {
    margin-top: 30px;
  }
}

.bubble:before {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  right: 30px;
  top: 38px;
  border: 10px solid;
  border-color: white transparent transparent white;
}

.did-you-know {
  font-family: SlatePro-Italic;
  margin-bottom: 100px;

  @media(max-width: 1700px) {
    margin-bottom: 50px;
  }
}