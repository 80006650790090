.right-column-house-container {
  position: relative;
  height: 100%;
  background: linear-gradient(180deg, rgba(226,245,253,1) 0%, rgba(94,190,225,1) 70%);

  .closed-house {
    position: absolute;
    width: 3000px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    @media(max-width: 1700px) {
      width: 2000px;
    }
  }
}