// Fonts
@font-face {
  font-family: 'SlatePro';
  src: local('SlatePro'), url(../fonts/SlatePro.otf) format('truetype');
}

@font-face {
  font-family: 'SlatePro-Bold';
  src: local('SlatePro-Bold'), url(../fonts/SlatePro-Bold.otf) format('truetype');
}

@font-face {
  font-family: 'SlatePro-Italic';
  src: local('SlatePro-Italic'), url(../fonts/SlatePro-Italic.otf) format('truetype');
}


// Color

$evrsrc-blue: #00aeef;
$evrsrc-light-green: #00b140;
$evrsrc-dark-green: #007749;
$side-panel-green: #007749;
