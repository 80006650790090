.thankyou-wrapper {
  background-color: $evrsrc-dark-green;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .close-btn {
    color: white;
    font-size: 3em;
    font-weight: bold;
    float: right;
    margin-right: 20px;
    cursor: pointer;
  }

  .ty-message-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;

    h1 {
      text-align: center;
      font-weight: bold;
      font-size: 3.5em;
      margin-bottom: 20px;
    }

    p {
      font-size: 1.3em;
      max-width: 600px;
    }

    img {
      width: 300px;
    }
  }

  .logo {
    width: 300px;
    margin: 0 0 70px 70px;
  }
}