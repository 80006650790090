.state-icon {
  background: $evrsrc-light-green;
  height: 220px;
  width: 220px;
  margin-right: 30px;
  border-radius: 10px;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1), 0 0 5px 2px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  padding-top: 20px;

  img {
    max-width: 130px;
    display: block;
    margin: auto;
  }

  h6 {
    color: white;
    text-align: center;
    font-family: SlatePro-Bold;
    font-size: 1.4em;
  }
}