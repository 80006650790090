.right-column-inner-house-container {
  position: relative;
  height: 100vh;
  width: 100%;
  background: linear-gradient(180deg, rgba(226,245,253,1) 0%, rgba(94,190,225,1) 70%);

  .open-house {
    position: absolute;
    width: 3000px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    @media (max-width: 1700px) {
      width: 2000px;
    }
  }

  .testy {
    @media (max-width: 1700px) {
      transform: translate(0, 0) scale(.67);
      height: 100vh;
      position: absolute;
      width: 100%;
      top: 0;
    }
  }

  .particle {
    z-index: -1;
    position: absolute;
    width: 100px;
  }

  .react-transform-element {
    transform-origin: center !important;
  }
}

.react-transform-component,
.react-transform-element {
  width: 100% !important;
  height: 100% !important;
}