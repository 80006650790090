body,
html {
    font-size: 16px;
    height: 100vh;
    overflow: hidden;
    margin: 0;
    font-family: SlatePro !important;

    @media (max-width: 1700px) {
        font-size: 14px;
    }
}

p {
    font-size: 1.3em;
    line-height: 30px;
}

#root {
    height: 100%;
}

.container-fluid {
    height: 100%;
}

.blue-btn {
    background-color: $evrsrc-blue;
    padding: 6px 30px;
    border-color: $evrsrc-blue;
    color: white;
    font-size: 1.2em;
    border-radius: 6px;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1), 0 0 5px 2px rgba(0, 0, 0, 0.1);
    line-height: 35px;
    height: 46px;
}

.green-btn {
    background-color: $evrsrc-light-green;
    padding: 6px 30px;
    border-color: $evrsrc-light-green;
    color: white;
    font-size: 1.2em;
    border-radius: 6px;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1), 0 0 5px 2px rgba(0, 0, 0, 0.1);
    line-height: 35px;
    height: 46px;
}

.full-height-columns {
    display: inline-flex;
    height: 100%;
}

.energy-icon {
    width: 60px;
    position: absolute;
    top: 50%;
    left: 50%;
}

.logo-state-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.flex-center {
    display: flex;
    align-items: center;
}
