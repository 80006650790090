.state-logo-container {

    margin-bottom: 100px;

    @media (max-width: 1700px) {
        margin-bottom: 50px;
    }

    .state-logo {
        width: 50px;
        margin-right: 15px;
    }

    h5 {
        color: white;
    }

}
