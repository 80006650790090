.startscreen-wrapper {
  background: linear-gradient(180deg, rgba(226,245,253,1) 0%, rgba(94,190,225,1) 70%);
  height: 100%;
  width: 100%;

  .startscreen-content-container {
    margin-left: 10%;
    padding-top: 10%;
    z-index: 99;
    position: relative;
  }

  .logo {
    width: 400px;
    margin-bottom: 50px;
    position: relative;
  }

  h1 {
    font-family: SlatePro-bold;
    color: white;
    margin-bottom: 30px;
    font-size: 3.75em;
    line-height: 100%;
    position: relative;
    z-index: 10;
    text-shadow: 1px 1px rgba(0, 0, 0, .4);
  }
}

.particle {
  z-index: 1;
  position: absolute;
  width: 100px;
}

.cloud {
  z-index: 1;
  width: 200px;
  position: absolute;
  right: -15%;
  top: 20%;
  transition: all 8s;
}

.start-house {
  z-index: 1;
  position: absolute;
  width: 3000px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media(max-width: 1700px) {
    width: 2000px;
  }
}