.video-modal-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  width: 100vw;
  height: 100vh;
  margin-top: 29px;
  background: rgba(0, 0, 0, .7);

  .video-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .video-modal-close-btn {
    font-size: 2em;
    position: absolute;
    right: -10%;
    top: -10%;
  }
}