.right-column-container .tap-icon-message-container {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  width: 300px;
  transition: all 1s;
  opacity: 0%;

  img {
    width: 40px;
    margin-right: 30px;
    align-self: flex-start;
  }

  p {
    color: rgb(82, 82, 82);
    background: white;
    margin-bottom: 0;
    padding: 10px 15px;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, .2);
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1), 0 0 5px 2px rgba(0, 0, 0, 0.1);
  }
}

.animated {
  position: absolute;
}