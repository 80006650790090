.title-body {

  h2 {
    font-family: SlatePro-Bold;
    font-size: 2.18em;
    margin-bottom: 20px;
  }

  h3 {
    font-family: SlatePro-Bold;
    font-size: 1.56em;
    margin-bottom: 20px;
  }

  p {
    margin-bottom: 45px;
    margin-bottom: 25px;
  }
}