.left-column-container {
  width: 500px;
  background-color: $side-panel-green;
  // height: 100%;
  padding: 70px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: white;
  z-index: 15;
  overflow: auto;

  img {
    margin-bottom: 30px;
    width: 300px;
  }  

  .logo {
    margin-bottom: 0px;
    width: 150px;
  }
}