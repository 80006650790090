.list-content-left-column {
  p:nth-of-type(1) {
    margin-bottom: 50px;
  }

  .email-title {
    margin-bottom: 10px !important;
  }

  input {
    width: 80%;
    margin-bottom: 20px;
    padding: 10px 10px;
    border-radius: 5px;
    border: none;
  }
}