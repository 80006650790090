.footer-bar {
  position: absolute;
  bottom: 0;
  z-index: 1000;
}

.blue-bar {
  background-color: $evrsrc-blue;
  width: 100vw;
  height: 15px;
}

.light-green-bar {
  background-color: $evrsrc-light-green;
  width: 100vw;
  height: 15px;
}

.dark-green-bar {
  background-color: $evrsrc-dark-green;
  width: 100vw;
  height: 15px;
}

.spacer-bar {
  background-color: white;
  width: 100vw;
  height: 7px;
}