.state-select-wrapper {
  height: 100vh;
  padding: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .logo {
    width: 400px;
    margin-bottom: 150px;
  }

  h1 {
    color: $evrsrc-dark-green;
    font-family: SlatePro-Bold;
    font-size: 3.5em;
  }

  p {
    color: grey;
    font-family: SlatePro-Italic;
    font-size: 1.5em;
    margin-bottom: 20px;
  }

  button {
    width: 200px;
  }
}