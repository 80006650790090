.learn-more-wrapper {

  h5 {
    padding-bottom: 0;
  }
  
  button {
    font-family: SlatePro-Italic;
    color: $evrsrc-blue !important;
    cursor: pointer;
    background: none;
    text-align: left;
    border: none;
    padding: 0;
    width: 200px;
  }
}